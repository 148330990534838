.App {
  text-align: center;
}
*{
  font-family: 'Montserrat', sans-serif;
}
/* *, h2, h3, h4, h5{
  overflow-x: hidden;
  overflow-y: visible;
} */
 .col{
  flex-basis: unset !important;
 }
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body, .card{
  background-color: #070707!important;
  color:antiquewhite!important
}
.nav-link, li{
  background-color: #343a40!important;
  color:antiquewhite!important
}
.nav-link{
  padding: 8px 16px!important;
  transition: 0.3s!important;
}
.nav-link:hover{
  color: #F06221!important;
}
div.bg-dark{
  background-color: #070707!important;
}
.navbar-nav{
  background-color: #343a40!important;
}
.container{
  margin-top: 45px;
}
.row{
  justify-content: center;
  align-items: center;
  margin: 32px 0;
  position: relative;
}
@media screen and (max-width: 768px) {
  .topfoldRow{
    margin: 16px 0;
  }
  .overlay p{
    font-size: 14px;
  }
  .bannerDiv{
    height: 60vh!important;
    margin-top: 188px !important;
  }
  .row:not(.retainRow) {
    flex-direction: column!important;
  }
  .row{
    overflow-y: visible;
  }
  .container{
    padding: 0!important;
    overflow: hidden;
  }
  .mandala1, .mandala2{
    width: 70%!important;
  }
  .overlay{
    padding: 0 24px!important;
  }
  .dk-hide{
    display: block!important;
  }
  .mb-hide{
    display: none!important;
}
}
.orange{
  color: #F06221!important;
}
.dk-hide{
    display: none;
}
.mb-hide{
  display: block;
}
.bannerDiv{
  background-image: url("../public/images/theenmura\ 3-min.jpg");
  height: 70vh;
  width: 100%;
  background-position: center;
  background-size: cover;
  position: relative;
}
.overlay{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  padding: 0 48px;
}
.overlay *{
  text-align: center;
  max-width: 1000px;
}
.mandala1{
  position: absolute;
  opacity: 0.2;
  top: 0%;
  width: 40%;
  left: -20%;
}

.mandala2{
  position: absolute;
  opacity: 0.2;
  top: 0%;
  width: 40%;
  right: -20%;
}
.list-group{
  border-radius: 33px;
  overflow: hidden;
  border: 2px solid antiquewhite !important;
}
.book-cta{
  display: block;
  color: antiquewhite;
  background-color: #F06221;
  padding: 8px 16px;
  border-radius: 33px;
  text-decoration: none;
  transition: 0.3s;
}
.book-cta:hover{
  color: #F06221;
  background-color: antiquewhite;
  text-decoration: none;

}
.cta-container{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin: 24px auto 0;
}
.cta-container a{
  font-weight: 600;
}
.location-link{
  color: antiquewhite;
  text-decoration: none;
  transition: 0.3s;
  font-weight: 600;
}
.location-link:hover{
  color: #F06221;
  text-decoration: none;
}